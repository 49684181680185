import { z } from "zod";
import { availableFonts } from "../constants";

const dochatConfigIdMissingStr = "Dochat config id cannot be empty";

const dochatConfigNameMissingStr = "Dochat config name cannot be empty";

const sessionIdInvalidLength = "Session id must be 24 characters long";

export const dochatConfigIdSchema = z
  .string({
    required_error: dochatConfigIdMissingStr,
  })
  .trim()
  .min(1, { message: dochatConfigIdMissingStr })
  .uuid();

const rasaUrlSchema = z.string().url().max(1048);

export const dochatConfigSchema = z.object({
  id: dochatConfigIdSchema,
  name: z
    .string({ required_error: dochatConfigNameMissingStr })
    .trim()
    .min(1, { message: dochatConfigNameMissingStr }),
  orchestratorId: z.string().optional(),
  // If translate endpoint, don't forget to populate defaultModelLanguage in chatbotParams
  rasaUrl: rasaUrlSchema,
});

export const localeSchema = z.string().min(2).max(5);
export type Locale = z.infer<typeof localeSchema>;

const placeholderSchema = z.string().min(1).max(2048);
const titleSchema = z.string().min(1).max(256);
const localeConfigSchema = z.object({
  disclaimerText: z.string().max(2048).nullish(),
  isDefault: z.boolean().nullish(),
  placeholder: placeholderSchema.nullish(),
  // Only if dochatConfigSchema.rasaUrl is a translate endpoint
  rasaUrl: rasaUrlSchema.nullish(),
  title: titleSchema.nullish(),
});
export type LocaleConfig = z.infer<typeof localeConfigSchema>;

export const availableLocalesSchema = z.record(localeSchema, localeConfigSchema);
export type AvailableLocales = z.infer<typeof availableLocalesSchema>;

export const conversationContextSchema = z
  .object({
    brand: z.string().nullable(),
    availableLocales: availableLocalesSchema.nullish(),
    data: z.record(z.string(), z.string()).nullable(),
    entities: z.record(z.string(), z.string()).nullable(),
    externalRef: z.string().nullable(),
    intent: z.string().nullable(),
    language: z.string().nullable(),
    startMessage: z.string().nullable(),
  })
  .partial();

const colorSchema = z.string().regex(/^#[0-9a-fA-F]{3,6}$/);
const disclaimerPositions = ["top", "bottom"] as const;

export const chatbotParamsSchema = localeConfigSchema
  .omit({ isDefault: true, placeholder: true, rasaUrl: true })
  .extend({
    availableLocales: availableLocalesSchema.nullish(),
    colorBackgroundMessages: colorSchema,
    colorPrimary1: colorSchema,
    colorPrimary2: colorSchema,
    // Only if dochatConfigSchema.rasaUrl is a translate endpoint
    //  and there's no localeConfigSchema.rasaUrl
    defaultModelLanguage: localeSchema.nullish(),
    disclaimerActivate: z.boolean().nullish(),
    disclaimerBgcolor: colorSchema.nullish(),
    disclaimerPosition: z.enum(disclaimerPositions).nullish(),
    enableCloseButton: z.boolean().nullish(),
    enableRefreshButton: z.boolean().nullish(),
    fontId: z.nativeEnum(availableFonts).nullish().default(availableFonts.Roboto),
    handoff: z
      .object({
        contact: z.string().email(),
        email: z.object({
          introduction: z.string().min(1).max(256),
          sendButton: z.string().min(1).max(32),
          title: z.string().min(1).max(128),
        }),
        form: z.object({
          emailLabel: z.string().min(1).max(32),
          inquiryPlaceholder: z.string().min(1).max(32),
          introduction: z.string().min(1).max(256),
          submit: z.string().min(1).max(32),
        }),
      })
      .nullish(),
    logoDataUri: z.string().min(1).max(102_400 /* 100 ko */),
    noUserInput: z.boolean().nullish(),
    onlyLastMessage: z.boolean().nullish(),
    placeholder: placeholderSchema,
    rasaContext: z.record(z.string(), conversationContextSchema).nullish(),
    showPoweredByDialonce: z.boolean().nullish(),
    simulateStreaming: z.boolean().nullish(),
    // This any will be replaced by a more specific type when we remove cute or we know the type
    staticContextsMap: z.record(z.string().min(1), z.any()).nullish(),
    stylesheets: z.record(z.string().min(1).max(256), z.string().min(1).max(8192)).nullish(),
    title: titleSchema,
  });

export const sessionIdSchema = z.string().length(24, { message: sessionIdInvalidLength });
export const APIDochatConfigSchema = z
  .object({
    brandFromQParams: z.string().nullish(),
    callingURL: z.string().nullish(),
    chatbotStaticCtxMapKey: z.string().nullish(),
    digitalDynamicCtx: conversationContextSchema.nullish(),
    id: dochatConfigIdSchema,
    pinCode: z.string().nullish(),
    positionId: z.string().nullish(),
    sessionId: sessionIdSchema.nullish(),
    theming: z.string().nullish(),
    userLang: z.string().nullish(),
  })
  .required();

export const APIDochatConfigWithoutSessionSchema = z
  .object({ id: dochatConfigIdSchema })
  .required();

export const APIChatbotParamsSchema = z.object({
  id: dochatConfigIdSchema,
  chatbotParams: chatbotParamsSchema,
});

export const APIConversationStartParamsSchema = z
  .object({
    analyticId: z.string().uuid(),
    chatbotParams: chatbotParamsSchema,
    company_cuid: z.string().min(1),
    computedAvailableLocales: availableLocalesSchema.nullish(),
    computedBrand: z.string().nullish(),
    computedLanguage: z.string().nullish(),
    computedStartMessage: z.string(),
    computedStaticData: z.record(z.string(), z.string()).nullable(),
    createdPinCode: z.string().nullish(),
    id: dochatConfigIdSchema,
    orchestrator_id: z.string().min(1),
    rasaUrl: z.string().min(1),
    outOfCredits: z.boolean().optional(),
  })
  .required();

export const contrackResponseSchema = z.object({
  context: conversationContextSchema,
});

export type APIDochatConfig = z.infer<typeof APIDochatConfigSchema>;
export type APIDochatConfigWithoutSession = z.infer<typeof APIDochatConfigWithoutSessionSchema>;
export type ChatbotParams = z.infer<typeof chatbotParamsSchema>;
export type ConversationContext = z.infer<typeof conversationContextSchema>;
export type ConversationStartParams = z.infer<typeof APIConversationStartParamsSchema>;
export type DochatConfig = z.infer<typeof dochatConfigSchema>;
export type DochatConfigId = z.infer<typeof dochatConfigIdSchema>;
