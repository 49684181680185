import type { StaticDatePickerProps as MUIStaticDatePickerProps } from "@mui/x-date-pickers";
import {
  LocalizationProvider as MUILocalizationProvider,
  StaticDatePicker as MUIStaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB, fr } from "date-fns/locale";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";
import { defaultLanguage } from "../../../constants/language";
import { Language } from "../../../types/language";

type StaticDatePickerProps = MUIStaticDatePickerProps<Date> & {
  language?: Language;
};

const languages = {
  [Language.fr]: fr,
  [Language.en]: enGB,
};

export const StaticDatePicker = forwardRef(
  (
    { value, language = defaultLanguage, ...props }: StaticDatePickerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <MUILocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={languages[language]}>
        <MUIStaticDatePicker ref={ref} value={value} {...props} />
      </MUILocalizationProvider>
    );
  }
);
