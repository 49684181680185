import z from "zod";

export enum FsmDefMetaTypes {
  axios = "axios",
  doBot = "doBot",
  form = "form",
  liveChat = "liveChat",
  markdown = "markdown",
}
const AxiosHeadersSchema = z.record(z.string(), z.any());
const MethodSchema = z.union([
  z.literal("get"),
  z.literal("GET"),
  z.literal("delete"),
  z.literal("DELETE"),
  z.literal("post"),
  z.literal("POST"),
  z.literal("put"),
  z.literal("PUT"),
  z.literal("patch"),
  z.literal("PATCH"),
]);

const axiosRequestConfigSchema = z
  .object({
    url: z.string().optional(),
    method: z.union([MethodSchema, z.string()]).optional(),
    baseURL: z.string().optional(),
    headers: z.union([AxiosHeadersSchema, z.any()]).optional(),
    params: z.any().optional(),
    data: z.any().optional(),
  })
  .passthrough();

export const fsmDefStateNamesSchema = z.enum([
  FsmDefMetaTypes.axios,
  FsmDefMetaTypes.doBot,
  FsmDefMetaTypes.form,
  FsmDefMetaTypes.liveChat,
  FsmDefMetaTypes.markdown,
]);

export const fsmDefAxiosStateSchema = z.object({
  meta: z.object({
    type: z.literal(FsmDefMetaTypes.axios),
    options: axiosRequestConfigSchema,
  }),
  on: z.object({
    success: z.object({
      target: z.string(),
    }),
    error: z.object({
      target: z.string(),
    }),
  }),
});

export type FsmDefAxiosState = z.infer<typeof fsmDefAxiosStateSchema>;

export const fsmDefDoBotStateSchema = z.object({
  meta: z.object({
    type: z.literal(FsmDefMetaTypes.doBot),
    action: z.string(),
    payloadTransformer: z.string(),
  }),
});

export type FsmDefDoBotState = z.infer<typeof fsmDefDoBotStateSchema>;

const fsmDefFormSchema = z
  .object({
    fields: z.record(
      z.string(),
      z
        .object({
          type: z.string(),
          props: z
            .object({
              label: z.string(),
            })
            .passthrough(),
        })
        .passthrough()
    ),
    submit: z.string(),
  })
  .passthrough();

export const FsmDefFormStateSchema = z.object({
  meta: z.object({
    type: z.literal(FsmDefMetaTypes.form),
    formSchema: fsmDefFormSchema,
  }),
  on: z.object({
    formOnSubmit: z.object({
      target: z.string(),
    }),
  }),
});
export type FsmDefFormState = z.infer<typeof FsmDefFormStateSchema>;

const fsmDefLiveChatStateSchema = z
  .object({
    meta: z.object({
      type: z.literal(FsmDefMetaTypes.liveChat),
      liveChatConfigId: z.string(),
      payloadTransformer: z.string(),
    }),
  })
  .passthrough();

export type FsmDefLiveChatState = z.infer<typeof fsmDefLiveChatStateSchema>;

const fsmDefMarkdownStateSchema = z.object({
  meta: z.object({
    type: z.literal(FsmDefMetaTypes.markdown),
    markdown: z.string(),
  }),
});

export type FsmDefMarkdownState = z.infer<typeof fsmDefMarkdownStateSchema>;

export const fsmDefSchema = z
  .object({
    initial: z.string(),
    states: z.record(
      z.string(),
      z.union([
        fsmDefAxiosStateSchema,
        fsmDefDoBotStateSchema,
        FsmDefFormStateSchema,
        fsmDefLiveChatStateSchema,
        fsmDefMarkdownStateSchema,
      ])
    ),
  })
  // check if initial is a key in states
  .refine(
    (val) => {
      try {
        return !!val.states[val.initial];
      } catch (e) {
        return false;
      }
    },
    {
      message: "Initial state must be a key in states",
      path: ["states", "intial"],
    }
  );
