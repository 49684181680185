import CloseIcon from "@mui/icons-material/Close";
import type {
  DialogActionsProps as MUIDialogActionsProps,
  DialogContentProps as MUIDialogContentProps,
  DialogProps as MUIDialogProps,
  DialogTitleProps as MUIDialogTitleProps,
} from "@mui/material";
import {
  Dialog as MUIDialog,
  DialogActions as MUIDialogActions,
  DialogContent as MUIDialogContent,
  DialogTitle as MUIDialogTitle,
} from "@mui/material";
import type { ReactNode } from "react";
import React, { forwardRef } from "react";
import type { ButtonColor, ButtonSize, ButtonVariant } from "../../../types";
import { Button } from "../../input-selection";
import { Progress } from "../../loading";

export type CustomDialogAction = {
  buttonId?: string;
  color?: ButtonColor;
  disabled?: boolean;
  formId?: string;
  indexFromRight: number;
  label: string;
  loading?: boolean;
  onClick?: () => void | Promise<void>;
  size?: ButtonSize;
  type?: "submit" | "reset" | "button";
  variant?: ButtonVariant;
};

export type DialogProps = Omit<MUIDialogProps, "open"> & {
  actions?: CustomDialogAction[];
  actionsProps?: MUIDialogActionsProps;
  backdropOnly?: boolean;
  closeButton?: boolean;
  children?: ReactNode;
  contentProps?: MUIDialogContentProps;
  onClose: () => void;
  open?: boolean;
  title?: string;
  titleProps?: MUIDialogTitleProps;
};

export type DialogButtonProps = Omit<CustomDialogAction, "indexFromRight" | "label"> & {
  form?: string;
  id?: string;
  key?: string;
};

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      actions = [],
      actionsProps,
      backdropOnly = false,
      children,
      closeButton = true,
      contentProps,
      onClose,
      open = false,
      title,
      titleProps,
      ...props
    },
    ref
  ) => {
    const dialogTitleId = "dialog-title";

    const CustomTitle = () => {
      if (!title) return null;

      return (
        <MUIDialogTitle id={dialogTitleId} {...titleProps}>
          {title}
        </MUIDialogTitle>
      );
    };

    const CustomActions = () => {
      if (actions.length === 0) return null;

      const ActionsButtons = () => {
        return (
          <MUIDialogActions {...actionsProps}>
            {actions
              .sort((a, b) => b.indexFromRight - a.indexFromRight)
              .map((action) => {
                const { label, ...actionWithoutLabel } = action;
                const buttonProps: DialogButtonProps = {
                  id: action.buttonId ?? undefined,
                  color: action.color ?? "primary",
                  disabled: action.disabled,
                  loading: action.loading ?? false,
                  size: action.size ?? "md",
                  variant: action.variant ?? "contained",
                };

                switch (actionWithoutLabel.type) {
                  case "submit":
                    buttonProps.form = actionWithoutLabel.formId;
                    buttonProps.type = "submit";
                    break;
                  case "reset":
                    buttonProps.form = actionWithoutLabel.formId;
                    buttonProps.type = "reset";
                    break;
                  default:
                    buttonProps.onClick = actionWithoutLabel.onClick;
                    break;
                }

                return (
                  <Button key={action.label} {...buttonProps}>
                    {action.label}
                  </Button>
                );
              })}
          </MUIDialogActions>
        );
      };

      return <ActionsButtons />;
    };

    return (
      <MUIDialog
        aria-labelledby={dialogTitleId}
        ref={ref}
        {...props}
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: backdropOnly
            ? {
                alignItems: "center",
                backgroundColor: "transparent",
                border: "none!important",
                boxShadow: "none",
                display: "flex",
                justifyContent: "center",
                overflow: "visible",
              }
            : undefined,
        }}
      >
        {backdropOnly ? (
          <Progress color="secondary" />
        ) : (
          <>
            <CustomTitle />

            {closeButton && (
              <CloseIcon
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  cursor: "pointer",
                  position: "absolute",
                  right: 16,
                  top: 20,
                }}
              />
            )}

            {children && (
              <MUIDialogContent {...contentProps}>
                {React.Children.toArray(children).filter((child) => child)}
              </MUIDialogContent>
            )}

            <CustomActions />
          </>
        )}
      </MUIDialog>
    );
  }
);
