import { z } from "zod";
import { fsmDefSchema } from "../types";
import { ChatCustomBlockTypes } from "../types/Chat";

export const baseBlockSchema = z.object({
  messageId: z.string().optional(),
  label: z.string().optional(),
  // id will be created by do-chat internally,
  // added in schema just so it's available for all block types
  id: z.string().optional(),
  timetableCuid: z.string().nullish(),
  in_oh: z.boolean().optional(),
});

export const chatCustomBlockTypesSchema = z.nativeEnum(ChatCustomBlockTypes);

export const metadataSchema = z.record(z.string(), z.unknown());

export const blockTextSchema = baseBlockSchema
  .extend({
    type: z.literal(chatCustomBlockTypesSchema.enum["text"]),
    text: z.string(),
    metadata: z.union([metadataSchema.array(), z.string()]).optional(),
  })
  .passthrough();

export const blockDotsSchema = baseBlockSchema.extend({
  type: z.literal(chatCustomBlockTypesSchema.enum["bouncingDots"]),
});

export const blockStepsSchema = baseBlockSchema.extend({
  type: z.literal(chatCustomBlockTypesSchema.enum["steps"]),
  fsmDef: fsmDefSchema,
  label: z.string().optional(),
});

export const liveChatContextSchema = z
  .object({
    CHAT_HISTORY: z
      .array(
        z.object({
          type: z.union([z.literal("user"), z.literal("bot")]),
          message: z.string(),
        })
      )
      .optional(),
  })
  .passthrough();

export const blockLiveChatSchema = z.object({
  type: z.literal(chatCustomBlockTypesSchema.enum["liveChat"]),
  chatliveConfigId: z.number(),
  // We parse context separately with safeParse, so the whole message isn't thrown out
  // in case the format is not right.
  // Expected: liveChatContextSchema. Previously z.string() containing a oldLiveChatContextSchema
  context: z.unknown().optional(),
  // next is to fix typing in ChatMessageUi.tsx:
  // called in <Grow in key={`block-${id}-${idx}-${block?.label}`}>
  label: z.string().optional(),
  timetableCuid: z.string().nullish(),
  in_oh: z.boolean().optional(),
});

export const liveChatSchema = z.object({
  configId: z.string(),
  payload: z.record(z.string()),
});

export const chatFastActionSchema = z
  .object({
    appName: z.string().optional(),
    appId: z.string().optional(),
    code: z.string().optional(),
    fsmDef: fsmDefSchema.optional(),
    id: z.string().optional(),
    liveChat: liveChatSchema.optional(),
    metadata: metadataSchema.array().optional(),
    packageName: z.string().optional(),
    payload: z.string().optional(),
    tel: z.string().optional(),
    template: z.string().nullish(),
    title: z.string().optional(),
    url: z.string().optional(),
    timetableCuid: z.string().nullish(),
    in_oh: z.boolean().optional(),
  })
  .passthrough();

export const blockButtonsSchema = baseBlockSchema.extend({
  type: z.literal(chatCustomBlockTypesSchema.enum["buttons"]),
  buttons: chatFastActionSchema.array(),
});

export const blockEventSchema = baseBlockSchema
  .extend({
    type: z.literal(chatCustomBlockTypesSchema.enum["event"]),
  })
  .passthrough();

export const chatCustomBlockSchema = z.discriminatedUnion("type", [
  blockButtonsSchema,
  blockDotsSchema,
  blockLiveChatSchema,
  blockStepsSchema,
  blockTextSchema,
  blockEventSchema,
]);

// Catch-all block type, to just ignore unknown/new types, rather than crashing.
export const blockOtherSchema = baseBlockSchema
  .extend({
    type: z
      .string()
      .refine((val) => !Object.values(ChatCustomBlockTypes).includes(val as ChatCustomBlockTypes)),
  })
  .passthrough();

export const chatOpenCustomBlockSchema = z.union([chatCustomBlockSchema, blockOtherSchema]);

export const chatMessageSchema = z.object({
  blocks: chatCustomBlockSchema.array().optional(),
  events: z
    .object({
      disabledInput: z.boolean().optional(),
    })
    .optional(),
  id: z.string().optional(),
  metadata: z.union([metadataSchema.array(), z.string()]).optional().nullable(),
  user: z.boolean().optional(),
});
